import React from 'react';

import IIconProps from './IIconProps';

const Unknown: React.FC<IIconProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 57}
      height={height || 75}
      viewBox="0 0 57 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5929 0C17.9007 0 10.3276 4.79048 4.38191 13.3339C3.30335 14.8837 3.63587 17.0086 5.14041 18.1494L11.4595 22.9408C12.9789 24.0929 15.1409 23.8235 16.3305 22.3332C19.9998 17.7363 22.7216 15.0896 28.4531 15.0896C32.9595 15.0896 38.5336 17.9899 38.5336 22.3598C38.5336 25.6633 35.8065 27.3599 31.3569 29.8545C26.1679 32.7637 19.3013 36.3842 19.3013 45.4412V46.875C19.3013 48.8167 20.8753 50.3906 22.817 50.3906H33.4328C35.3745 50.3906 36.9485 48.8167 36.9485 46.875V46.0293C36.9485 39.751 55.2983 39.4896 55.2983 22.5C55.2984 9.70547 42.0266 0 29.5929 0ZM28.125 54.7059C22.5299 54.7059 17.9779 59.2579 17.9779 64.853C17.9779 70.448 22.5299 75 28.125 75C33.7201 75 38.2721 70.448 38.2721 64.8529C38.2721 59.2578 33.7201 54.7059 28.125 54.7059Z"
        fill={color || 'black'}
      />
    </svg>
  );
};

export default Unknown;
