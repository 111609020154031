import React from 'react';

import IIconProps from './IIconProps';

const Bitcoin: React.FC<IIconProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || 75}
      height={height || 75}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.8281 37.5C73.8281 57.5635 57.5635 73.8281 37.5 73.8281C17.4365 73.8281 1.17188 57.5635 1.17188 37.5C1.17188 17.4365 17.4365 1.17188 37.5 1.17188C57.5635 1.17188 73.8281 17.4365 73.8281 37.5ZM53.0785 32.3247C53.8017 27.4909 50.1208 24.8922 45.0877 23.1587L46.7205 16.6106L42.7342 15.6173L41.1447 21.9929C40.0967 21.7317 39.0204 21.4854 37.9509 21.2414L39.5518 14.8239L35.5677 13.8306L33.934 20.3764C33.0665 20.1788 32.215 19.9835 31.3885 19.7782L31.3931 19.7577L25.8955 18.3851L24.8351 22.6422C24.8351 22.6422 27.7928 23.32 27.7304 23.3619C29.3449 23.7649 29.6367 24.8332 29.5878 25.68L27.728 33.1397C27.8394 33.1682 27.9835 33.209 28.1424 33.2726C28.0096 33.2396 27.8676 33.2033 27.7211 33.1682L25.1143 43.6181C24.9167 44.1085 24.416 44.8441 23.2875 44.5649C23.3272 44.6228 20.39 43.8417 20.39 43.8417L18.411 48.4043L23.5986 49.6973C24.5637 49.9392 25.5095 50.1923 26.4404 50.4306L24.7907 57.0536L28.7725 58.0469L30.4062 51.4942C31.4621 51.7804 32.5211 52.0552 33.583 52.3185L31.9548 58.8404L35.9411 59.8337L37.5908 53.2232C44.3883 54.5095 49.4999 53.9906 51.6513 47.8433C53.3851 42.8936 51.565 40.0384 47.9886 38.1766C50.5931 37.5763 52.5551 35.8633 53.0785 32.3247V32.3247ZM43.9705 45.0951C42.7386 50.0448 34.4039 47.3689 31.7017 46.698L33.8908 37.9238C36.5928 38.598 45.258 39.9331 43.9705 45.0951ZM45.2035 32.2532C44.0795 36.7556 37.1423 34.4681 34.892 33.9073L36.8766 25.9493C39.127 26.51 46.374 27.5566 45.2035 32.2532Z"
        fill={color || 'black'}
      />
    </svg>
  );
};

export default Bitcoin;
